import { useState, useEffect, useRef } from "react";
import { 
    FormControl, 
    FormLabel, 
    Input, 
    FormErrorMessage, 
    Stack, 
    InputGroup,
    InputRightAddon,
    Switch,
    Button, 
    Image,
    ListItem,
    UnorderedList,
    Center, 
    Flex, 
    Text,
    Box,
    FormHelperText

} from '@chakra-ui/react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL  } from "firebase/storage";
import {  FaRegEye } from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
import { pdfjs } from "react-pdf";
import axios from 'axios';
import logo from '../files/MOLO boost logo.png'
import SocialMediaWrapper from "../components/SMWrapper";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




const app = firebaseApp
const analytics = getAnalytics(app);


const mql = window.matchMedia('(max-width: 480px)');

let mobileView = mql.matches;






export default function SignUpForm(props) {
    const [businessName, setBusinessName] = useState("");
    const [placesID, setPlacesID] = useState(null);
    const [businessLogo, setBusinessLogo] = useState(null);
    // const [businessAddress, setBusinessAddress] = useState("");
    const [file, setFile] = useState(null);
    const [fileName1, setFileName1] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isGoogleSignIn, setIsGoogleSignIn] = useState(false);
    const [googleUserData, setGoogleUserData] = useState(null); // Store Google Auth data
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [urlError, setUrlError] = useState(false);
    const [manualReviewLink, setManualReviewLink] = useState("");
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const businessTypeRef = useRef();
    const urlRef = useRef();
    const [firebasePaths, setFirebasPaths] = useState([])

    const autoCompleteRef = useRef();
    const businessNameRef = useRef();
    const [showPassword, setShowPassword] = useState(false);

    const [isAnnual, setIsAnnual] = useState(true);
    
    const [faviconUrl, setFaviconUrl] = useState("");

  


    const options = {
        // componentRestrictions: { country: "us" },
        fields: ["place_id", "icon", "name", "address_components"],
        types: ["establishment"]
    };
    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        businessNameRef.current,
        options
        );
        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            setBusinessName(place.name);
            setPlacesID(place.place_id)
            // setBusinessAddress(place.address_components[3].long_name + ', ' +place.address_components[5].long_name)
        });
    }, []);

    const validateUrl = (url) => {
        const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        return urlPattern.test(url);
    };

    const handleWebsiteChange = (e) => {
        const url = e.target.value.trim();
        setWebsiteUrl(url);
        setUrlError(!validateUrl(url));
        const input = e.target.value.trim();
        setWebsiteUrl(input);

        // Determine if the user has typed a TLD (e.g., .com, .net, .org)
        const hasTld = /\.[a-z]{2,}$/.test(input);

        // Construct favicon URL
        const imgUrl = hasTld
            ? `${input}/favicon.ico`
            : `${input}.com/favicon.ico`;

        // Update favicon URL
        setFaviconUrl(imgUrl);
    };

  

  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
  };

  const handleFileChange = (event) => {
    const photo = event.target.files[0];
    setFile(photo);
    setBusinessLogo(photo);
    setFileName1(new Date().getTime() + photo.name)
    }

    const errorCheck = () => {
        setIsLoading(true);
        console.log(placesID);
        console.log(manualReviewLink);
        // alert()
        
      
        if(
            placesID == null && manualReviewLink == ''
        ){
            alert('You must either select your business from the Business Name menu, or manually enter the review link')
            setIsLoading(false);
            return false;
        }
        if(
            // firstNameRef.current.value.length > 0 &&
            // lastNameRef.current.value.length > 0 &&
            // emailRef.current.value.length > 0 &&
            // passwordRef.current.value.length > 0 &&
            businessNameRef.current.value.length > 0 &&
            // businessTypeRef.current.value.length > 0 &&
            // businessLogo &&
            !urlError
            // websiteUrl.length > 0 &&
        ){
            setIsLoading(false);
            return true;
        }
        alert('Uh oh, please make sure all fields are filled out properly.')
        setIsLoading(false);
        return false;
    };

    const handleGoogleSignIn = async () => {
        const auth = getAuth(firebaseApp);
        const provider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            setGoogleUserData({
                firstName: user.displayName.split(" ")[0],
                lastName: user.displayName.split(" ")[1] || "",
                email: user.email,
                firebaseUID: user.uid
                
            });
            setIsGoogleSignIn(true);
        } catch (error) {
            console.error("Google Sign-In Error:", error.message);
            alert("Failed to sign in with Google.");
        }
    };

    const handleSubmit = async (event, price_id) => {
        event.preventDefault();
        if(!errorCheck()){
            return;
        }
        setIsLoading(true);

        let imgs = "";
        if (businessLogo) {
            const storage = getStorage(firebaseApp);
            const storageRef = ref(storage, (googleUserData?.email || emailRef.current.value) + '/' + fileName1);
            await uploadBytes(storageRef, businessLogo);
            imgs = await getDownloadURL(storageRef);
        }

        const data = {
            firstName: isGoogleSignIn ? googleUserData.firstName : firstNameRef.current.value.trim(),
            lastName: isGoogleSignIn ? googleUserData.lastName : lastNameRef.current.value.trim(),
            email: isGoogleSignIn ? googleUserData.email : emailRef.current.value.trim(),
            firebaseUID: googleUserData?.firebaseUID || null,
            password: isGoogleSignIn ? null : passwordRef.current.value.trim(),
            businessName: businessNameRef.current.value.trim(),
            businessType: "",
            places_id: placesID,
            price_id: price_id,
            manualReviewLink: manualReviewLink,
            websiteUrl: websiteUrl.trim(),
            Images: imgs,
        };

        try {
            const res = await axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com/api/registerUser', data);
            if (res.status === 200) {
                window.location = res.data.sessionURL;
            } else {
                alert("An error occurred. Please try again.");
            }
        } catch (error) {
            console.error("Submission Error:", error.message);
            alert("Failed to submit the form.");
        } finally {
            setIsLoading(false);
        }
        setIsLoading(false);
    };


  return (
    <Box>
        <SocialMediaWrapper>
            <Box w='100%' pt={6} pb={6} > 
                <Flex w={['95%', '60%']} m='0 auto' bg='white' borderRadius={'8px'} border={'solid 3px #eeecea '}>
                    <form style={{ width:'100%'}}>
                        <Stack spacing={4} p={[4, '2rem']}>
                            <Text margin='0 auto'  color={'#6da34d'} textAlign='center' fontSize="2rem" fontWeight="900">Let's get started — fill in your details.</Text>
                            <img src={logo} style={{width:'25%', margin:' 0 auto'}}/>

                            <Text color="#000" textAlign="center" fontSize="1.2rem" fontWeight="600">
    Sign up with your email or Google account
                            </Text>

                            {/* Google Sign-In Button */}
                            <Flex justifyContent="center" mb={4}>
                                <Button
                                    onClick={handleGoogleSignIn}
                                    bg="white"
                                    outline={'2px solid #4285F4'}
                                    color="#4285F4"
                                    _hover={{ bg: "#b7d4ff" }}
                                    w={['100%', '50%']}
                                    leftIcon={<img src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/768px-Google_%22G%22_logo.svg.png" alt="Google" style={{ width: '20px' }} />}
                                >
                                    Sign Up with Google
                                </Button>
                            </Flex>
                            {!isGoogleSignIn && (
                            <>
                            {/* Divider */}
                            <Flex alignItems="center" justifyContent="center" my={4}>
                                <Box flex="1" height="1px" bg="#ccc" />
                                <Text mx={2} color="#666" fontSize="0.9rem" fontWeight="500">
                                    OR
                                </Text>
                                <Box flex="1" height="1px" bg="#ccc" />
                            </Flex>

                            {/* Email and Password Form */}
                            
                            <FormControl isRequired>
                                <FormLabel color="#000" htmlFor="first-name">First Name</FormLabel>
                                <Input id="first-name" type="text" ref={firstNameRef} />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel color="#000" htmlFor="last-name">Last Name</FormLabel>
                                <Input id="last-name" type="text" ref={lastNameRef} />
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel color="#000" htmlFor="email">Email Address</FormLabel>
                                <Input id="email" type="email" ref={emailRef} />
                                <FormErrorMessage>Please enter a valid email address</FormErrorMessage>
                            </FormControl>

                            <FormControl isRequired>
                                <FormLabel color="#000" htmlFor="password">Password</FormLabel>
                                <InputGroup>
                                    <Input id="password" type={showPassword ? 'text' : 'password'} ref={passwordRef} />
                                    <InputRightAddon
                                        color="black"
                                        _hover={{ cursor: 'pointer' }}
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        <FaRegEye />
                                    </InputRightAddon>
                                </InputGroup>
                            </FormControl>
                            </>)}

                            {/* Divider */}
                            <Flex alignItems="center" justifyContent="center" my={6}>
                                <Box flex="1" height="1px" bg="#ccc" />
                                <Text mx={2} color="#666" fontSize="1.5rem" fontWeight="500">
                                    Business Information
                                </Text>
                                <Box flex="1" height="1px" bg="#ccc" />
                            </Flex>

                            <FormControl>
                                <FormLabel color='#000' htmlFor="business-name">Name of Business</FormLabel>
                                <Input 
                                    ref={businessNameRef} 
                                    id="business-name" 
                                    type="text" 
                                    value={businessName} 
                                    onChange={handleBusinessNameChange} 
                                />
                                <FormHelperText>Contact info@molostrategies.ca if you do not have a Google My Business listing.</FormHelperText>
                            </FormControl>

                            {/* Manual Place ID Input */}
                            <FormControl>
                                <Flex alignItems="center" justifyContent="space-between" my={4}>
                                    <Text fontWeight="600" color="#666">Can't find your review link?</Text>
                                    <Button 
                                        size="sm" 
                                        variant="outline" 
                                        colorScheme="blue"
                                        onClick={() => window.open('https://moloboost.ca/gmb-place-id-guide', '_blank')}
                                    >
                                        How-To Guide
                                    </Button>
                                </Flex>

                                <FormLabel color='#000' htmlFor="manual-place-id">
                                    Enter Google Review Link Manually
                                </FormLabel>
                                <Input 
                                    id="manual-place-id"
                                    type="text"
                                    placeholder="Enter your Google Review Link"
                                    value={manualReviewLink}
                                    onChange={(e) => setManualReviewLink(e.target.value)}
                                />
                                <FormHelperText>
                                    If Google Places Autocomplete doesn't find your business, 
                                    you can manually enter the Google Review Link Manually.
                                </FormHelperText>
                            </FormControl>
                            <FormControl  isInvalid={urlError}>
                                <FormLabel>Website URL (optional)</FormLabel>
                                <Flex alignItems="center">
                                    {/* Favicon */}
                                    <Box
                                        mr={2}
                                        w="30px"
                                        h="30px"
                                        borderRadius="4px"
                                        overflow="hidden"
                                        bg="gray.100"
                                    >
                                        <Image
                                            src={faviconUrl}
                                            alt="Favicon"
                                            fallbackSrc="https://tse3.mm.bing.net/th?id=OIP.8SvwJ9n50wZQ_jd0ZZMEpQHaHa&w=200&h=200&c=7"
                                            boxSize="30px"
                                            onError={(e) => {
                                                // Handle favicon fetch failure by showing a placeholder
                                                e.target.src = "https://tse3.mm.bing.net/th?id=OIP.8SvwJ9n50wZQ_jd0ZZMEpQHaHa&w=200&h=200&c=7";
                                            }}
                                        />
                                    </Box>

                                    {/* Input Field */}
                                    <Input
                                        ref={urlRef}
                                        type="text"
                                        value={websiteUrl}
                                        onChange={handleWebsiteChange}
                                        placeholder="https://example.com"
                                    />
                                </Flex>
                                {urlError ? (
                                    <FormErrorMessage>Invalid URL format.</FormErrorMessage>
                                ) : (
                                    <FormHelperText>Enter a valid URL starting with http or https.</FormHelperText>
                                )}
                            </FormControl>

                            {/* <FormControl >
                                <FormLabel color='#000' htmlFor="business-type">Type of Business</FormLabel>
                                <Input id="business-type" placeholder="Eg. Restaurant, Nail Salon, Barbershop, etc." type="text"  ref={businessTypeRef}/>
                            </FormControl> */}
                            <FormControl>
                                <FormLabel color='#000' htmlFor="photo">Upload a photo of your business's logo (optional)</FormLabel>
                                <Input type="file" id="photo" onChange={handleFileChange} accept="image/*"/>
                                <FormHelperText>Only .jpeg and .png files are supported</FormHelperText>
                                {file && <FormHelperText>{file.name}</FormHelperText>}
                            </FormControl>

                            {/* Divider */}
                            <Flex alignItems="center" justifyContent="center" my={6}>
                                <Box flex="1" height="1px" bg="#ccc" />
                                <Text mx={2} color="#666" fontSize="1.5rem" fontWeight="500">
                                    Choose a plan
                                </Text>
                                <Box flex="1" height="1px" bg="#ccc" />
                            </Flex>
                        

                            <Box p={1} m={6} mb='8vh' mt='25vh'>
                                        <Flex 
                                            direction={['column', 'column', 'row']} 
                                            justifyContent={'center'} 
                                            alignItems='center'
                                            gap={4}
                                        >
                                            {/* Standard Plan */}
                                            <Flex 
                                                w={['100%', '100%', '33.33%']}
                                                mb={[4, 4, 0]}
                                            > 
                                                <Flex 
                                                    direction={'column'} 
                                                    bg='white' 
                                                    borderRadius={12} 
                                                    p={4}  
                                                    border='2px solid #000' 
                                                    w='100%'
                                                >
                                                    <Text fontSize={'1.4rem'} fontWeight={600}>Standard</Text>
                                                    <Text fontSize={'1rem'} color='#999999'>No contract.</Text>

                                                    <Flex my={3}>
                                                        <Text fontSize={'1.4rem'} fontWeight={600}>$139.99</Text>
                                                        <Flex direction={'column'} mt={1} ml={1}>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>CAD</Text>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>/month</Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex><Text fontWeight={800}>Unlimited</Text> &nbsp;reviews</Flex>
                                                    <Flex mb={3}><Text fontWeight={800}>Unlimited</Text> &nbsp;contacts</Flex>
                                                    <Text fontWeight={800}>Get these powerful features:</Text>
                                                    <UnorderedList ml={8} spacing={1}>
                                                        <ListItem fontWeight={400}>Collect unlimited Google reviews</ListItem>
                                                        <ListItem fontWeight={400}>Collect unlimited contacts</ListItem>
                                                    </UnorderedList>

                                                    <Button 
                                                        isLoading={isLoading} 
                                                        disabled={isLoading}
                                                        onClick={(e) => handleSubmit(e,'price_1Q9Ez805dE8cwj98ILidBFCu')} 
                                                        my={6} 
                                                        bg='#5A7BFF' 
                                                        color='white' 
                                                        _hover={{ bg: '#5A7BFF' }} 
                                                        fontWeight={700}
                                                    >
                                                        Get started
                                                    </Button>
                                                </Flex>
                                            </Flex>

                                            {/* Boost Plan */}
                                            <Flex 
                                                w={['100%', '100%', '33.33%']}
                                                mb={[4, 4, 0]}
                                            > 
                                                <Flex 
                                                    direction={'column'} 
                                                    bg='white' 
                                                    borderRadius={12} 
                                                    p={4}  
                                                    border='2px solid #000' 
                                                    w='100%'
                                                >
                                                    <Text fontSize={'1.4rem'} fontWeight={600}>Boost</Text>
                                                    <Text fontSize={'1rem'} color='#999999'>6 month contract.</Text>

                                                    <Flex my={3}>
                                                        <Text fontSize={'1.4rem'} fontWeight={600}>$99.99</Text>
                                                        <Flex direction={'column'} mt={1} ml={1}>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>CAD</Text>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>/month</Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex><Text fontWeight={800}>Unlimited</Text> &nbsp;reviews</Flex>
                                                    <Flex mb={3}><Text fontWeight={800}>Unlimited</Text> &nbsp;contacts</Flex>
                                                    <Text fontWeight={800}>Get these powerful features:</Text>
                                                    <UnorderedList ml={8} spacing={1}>
                                                        <ListItem fontWeight={400}>Collect unlimited Google reviews</ListItem>
                                                        <ListItem fontWeight={400}>Collect unlimited contacts</ListItem>
                                                    </UnorderedList>

                                                    <Button 
                                                        isLoading={isLoading} 
                                                        disabled={isLoading}
                                                        onClick={(e) => handleSubmit(e,'price_1Q9Ezn05dE8cwj98c4TjHRbu')} 
                                                        my={6} 
                                                        bg='#5A7BFF' 
                                                        color='white' 
                                                        _hover={{ bg: '#5A7BFF' }} 
                                                        fontWeight={700}
                                                    >
                                                        Get started
                                                    </Button>
                                                </Flex>
                                            </Flex>

                                            {/* Boost+ Plan */}
                                            <Flex 
                                                w={['100%', '100%', '33.33%']}
                                            > 
                                                <Flex 
                                                    direction={'column'} 
                                                    bg='white' 
                                                    borderRadius={12} 
                                                    p={4}  
                                                    border='2px solid #000' 
                                                    w='100%'
                                                >
                                                    <Text fontSize={'1.4rem'} fontWeight={600}>Boost +</Text>
                                                    <Text fontSize={'1rem'} color='#999999'>12 month contract</Text>

                                                    <Flex my={3}>
                                                        <Text fontSize={'1.4rem'} fontWeight={600}>$79.99</Text>
                                                        <Flex direction={'column'} mt={1} ml={1}>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>CAD</Text>
                                                            <Text fontSize={'0.6rem'} color='#999999' lineHeight={'120%'}>/month</Text>
                                                        </Flex>
                                                    </Flex>

                                                    <Flex><Text fontWeight={800}>Unlimited</Text> &nbsp;reviews</Flex>
                                                    <Flex mb={3}><Text fontWeight={800}>Unlimited</Text> &nbsp;contacts</Flex>
                                                    <Text fontWeight={800}>Get these powerful features:</Text>
                                                    <UnorderedList ml={8} spacing={1}>
                                                        <ListItem fontWeight={400}>Collect unlimited Google reviews</ListItem>
                                                        <ListItem fontWeight={400}>Collect unlimited contacts</ListItem>
                                                    </UnorderedList>

                                                    <Button 
                                                        isLoading={isLoading} 
                                                        disabled={isLoading}
                                                        onClick={(e) => handleSubmit(e,'price_1Q9F0I05dE8cwj98L6xdl9ET')} 
                                                        my={6} 
                                                        bg='#5A7BFF' 
                                                        color='white' 
                                                        _hover={{ bg: '#5A7BFF' }} 
                                                        fontWeight={700}
                                                    >
                                                        Get started
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Box>
                            
                        </Stack>
                    </form>
                </Flex>
            </Box>
            
            <Text textAlign="center" mt={4} color="#666" fontSize="0.9rem">
                By signing up, you agree to our{' '}
                <a href="/privacy-policy" style={{ color: '#5A7BFF', textDecoration: 'underline' }}>
                    Privacy Policy
                </a>.
            </Text>
            
        </SocialMediaWrapper>
    </Box>
    
  )
}
