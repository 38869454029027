import { 
    Box, 
    Flex,
    Text,
    Button,
    InputGroup,
    InputLeftAddon,
    Input,
    Checkbox,
    Textarea,
    IconButton,
    Tooltip,
    Skeleton
} from '@chakra-ui/react';

import { FaUserAlt } from "react-icons/fa";
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { ArrowBackIcon, InfoIcon } from '@chakra-ui/icons';
import { HiOutlineMail } from "react-icons/hi";
import { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const DataCap = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [email, setEmail] = useState(null);
    const [reviewLink, setReviewLink] = useState(null);
    const [bName, setBName] = useState(null);
    const [isDataLoading, setDataLoading] = useState(false);
    const [invalidPhone, setInvalidPhone] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [confirmCheck, setConfirmCheck] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [dataLoaded, setDataLoaded] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [rating, setRating] = useState(0);
    const [reviewText, setReviewText] = useState("");
    const [isThankYouPage, setIsThankYouPage] = useState(false);

    const nameRef = useRef();
    const emailRef = useRef();

    useEffect(() => {
        // Check if the browser is in dark mode
        const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");
        setIsDarkMode(darkModeQuery.matches);

        const handleChange = (e) => setIsDarkMode(e.matches);
        darkModeQuery.addEventListener("change", handleChange);

        return () => darkModeQuery.removeEventListener("change", handleChange);
    }, []);

    const handleRating = (value) => {
        setRating(value);
    };

    const handleChange = (event) => {
        const inputValue = event.target.value.replace(/[^\d]/g, "");
        setPhoneNumber(inputValue);
        setIsValid(inputValue.length === 10 || inputValue.length === 0);
    };

    const formattedValue = (value) => {
        const regex = /^(\d{0,3})?(\d{0,3})?(\d{0,4})?$/;
        const match = regex.exec(value);
        if (match) {
            const areaCode = match[1] ? `(${match[1]}) ` : "";
            const firstPart = match[2];
            const secondPart = match[3];
            if (value.length <= 3) {
                return value;
            } else if (value.length <= 6) {
                return `${areaCode}${firstPart}`;
            } else {
                return `${areaCode}${firstPart}-${secondPart}`;
            }
        } else {
            return value;
        }
    };

    const handleCheckboxChange = event => {
        setIsChecked(event.target.checked);
    };

    const saveMember = () => {
        setConfirmCheck(false);
        if (!isChecked) {
            setConfirmCheck(true);
            return;
        }
    
        // Validate inputs
        if (nameRef.current.value.length === 0) {
            alert('Please enter a valid name.');
            return;
        }
    
        const emailValue = emailRef.current.value;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(emailValue)) {
            alert('Please enter a valid email address.');
            return;
        }
    
        if (rating === 0) {
            alert('Please select a star rating.');
            return;
        }
    
        setDataLoading(true);
    
        const phoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/;
        if (!phoneNumberRegex.test(phoneNumber)) {
            setDataLoading(false);
            setInvalidPhone(true);
            return;
        }
    
        const year = new Date().getFullYear();
        const month = new Date().getMonth() + 1;
        const day = new Date().getDate();
        const dateString = month + '/' + day + '/' + year;
    
        axios.post('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/contacts/add/" + email, {
            dateAdded: dateString,
            name: nameRef.current.value.trim(),
            phone: phoneNumber,
            email: emailValue,
            rating: rating,
            reviewText: rating < 4 ? reviewText : "", // Only include reviewText for ratings < 4
            addReview: true,
            notes: "",
        }).then(res => {
            setDataLoading(false);
            rating < 4 ? setIsThankYouPage(true) : window.location = reviewLink;
        }).catch(err => {
            rating < 4 ? setIsThankYouPage(true) : window.location = reviewLink;
            setDataLoading(false);
        });
    };

    const getBusiness = () => {
        var path = window.location.pathname;
        var parts = path.split('/');
        const accountID = parts.pop();

        axios.get('https://molo-boost-backend-27551f6a06a7.herokuapp.com' + "/api/accounts/get/"+accountID).then(res => {
            setDataLoaded(true);
            setBName(res.data.data.businessName);
            setReviewLink(res.data.data.reviewLink);
            setEmail(res.data.data.email);
        }).catch(err => {
            console.log(err);
        });
    };

    useEffect(() =>{
        getBusiness();
    }, []);

    const bgColor = isDarkMode ? "#1f1f1f" : "white";
    const textColor = isDarkMode ? "#e8e8e8" : "black";

    return (
        isThankYouPage ? (
            <Flex
                direction="column"
                justify="center"
                align="center"
                minHeight="100vh"
                bg="#1E88E5"
                color="white"
                textAlign="center"
                px={4}
            >
                <Box>
                    <Text fontSize="3xl" fontWeight="bold" mb={4}>
                        Thank You for Your Feedback!
                    </Text>
                    <Text fontSize="lg" mb={4}>
                        Your review for <strong>{bName}</strong> is greatly appreciated.
                    </Text>
                </Box>
            </Flex>
        ) : (
        <Flex 
            direction="column" 
            minHeight="100vh" 
            pb="25vh"
            overflowY="auto"
            px={3}
            bg={bgColor}
            color={textColor}
        >
            <Box>
                <Flex align="center" justifyContent="space-between" mb={4}>
                    <IconButton
                        icon={<ArrowBackIcon size={20} />}
                        aria-label="Go back"
                        variant="ghost"
                        onClick={() => window.history.back()}
                    />
                    <Text fontSize="lg" fontWeight="bold" flexGrow={1} textAlign="center">
                        <Skeleton isLoaded={dataLoaded}>
                        {bName}
                        </Skeleton>
                    </Text>
                </Flex>

                <Flex direction="column">
                    {invalidPhone &&
                        <Box p={1} textAlign="center">
                            <Text color="red.300">Phone number is invalid.</Text>
                        </Box>
                    }
                    {confirmCheck &&
                        <Box p={1} textAlign="center">
                            <Text color="red.300"> Agree to the terms to continue.</Text>
                        </Box>
                    }
                </Flex>

                <Flex direction="column" flex={1}>
                    <Flex align="center" mb={6}>
                        <Box>
                            <Text fontWeight="medium">Leave a review</Text>
                            <Flex align="center">
                                <Text fontSize="sm" color="gray.500" mr={1}>Posting publicly across Google</Text>
                                <Tooltip label="Information about posting reviews" fontSize="sm">
                                    <InfoIcon color="gray.500" />
                                </Tooltip>
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex justifyContent="center" mb={6}>
                        {[1, 2, 3, 4, 5].map((value) => (
                            <Box
                                key={value}
                                as="button"
                                onClick={() => handleRating(value)}
                                mx={1}
                            >
                                {rating >= value ? (
                                    <AiFillStar color="gold" size={36} />
                                ) : (
                                    <AiOutlineStar color="gray" size={36} />
                                )}
                            </Box>
                        ))}
                    </Flex>

                    {/* Conditional Feedback Text Area - Only show for ratings 1-3 */}
                    {rating > 0 && rating < 4 && (
                        <Textarea
                        
                            placeholder="Share details of your own experience at this place"
                            size="md"
                            rows={6}
                            mb={6}
                            value={reviewText}
                            onChange={(e) => setReviewText(e.target.value)}
                        />
                    )}

                    <InputGroup mb={4}>
                        <InputLeftAddon
                            bg={isDarkMode ? "#333" : "#f7f7f7"}
                            color={isDarkMode ? "#e8e8e8" : "black"}
                            borderColor={isDarkMode ? "#444" : "#e2e8f0"}
                        >
                            <FaUserAlt />
                        </InputLeftAddon>
                        <Input
                            placeholder="Name"
                            ref={nameRef}
                            bg={isDarkMode ? "#1f1f1f" : "white"}
                            color={isDarkMode ? "#e8e8e8" : "black"}
                            borderColor={isDarkMode ? "#444" : "#e2e8f0"}
                            _placeholder={{ color: isDarkMode ? "#888" : "#a0aec0" }}
                        />
                    </InputGroup>
                    <InputGroup mb={4}>
                        <InputLeftAddon
                            bg={isDarkMode ? "#333" : "#f7f7f7"}
                            color={isDarkMode ? "#e8e8e8" : "black"}
                            borderColor={isDarkMode ? "#444" : "#e2e8f0"}
                        >
                            <HiOutlineMail />
                        </InputLeftAddon>
                        <Input
                            placeholder="Email"
                            ref={emailRef}
                            bg={isDarkMode ? "#1f1f1f" : "white"}
                            color={isDarkMode ? "#e8e8e8" : "black"}
                            borderColor={isDarkMode ? "#444" : "#e2e8f0"}
                            _placeholder={{ color: isDarkMode ? "#888" : "#a0aec0" }}
                        />
                    </InputGroup>
                    <InputGroup mb={4}>
                        <InputLeftAddon
                            bg={isDarkMode ? "#333" : "#f7f7f7"}
                            color={isDarkMode ? "#e8e8e8" : "black"}
                            borderColor={isDarkMode ? "#444" : "#e2e8f0"}
                        >
                            +1
                        </InputLeftAddon>
                        <Input
                            type="tel"
                            placeholder="(123) 456-7890"
                            value={formattedValue(phoneNumber)}
                            onChange={handleChange}
                            isInvalid={!isValid}
                            maxLength={14}
                            bg={isDarkMode ? "#1f1f1f" : "white"}
                            color={isDarkMode ? "#e8e8e8" : "black"}
                            borderColor={isDarkMode ? "#444" : "#e2e8f0"}
                            _placeholder={{ color: isDarkMode ? "#888" : "#a0aec0" }}
                        />
                    </InputGroup>
                </Flex>
            </Box>

            <Box
                position="fixed"
                bottom="0"
                left="0"
                width="100%"
                bg={isDarkMode ? "#1f1f1f" : "white"}
                py={4}
                px={3}
                zIndex={10}
            >
                <Checkbox
                    mb={4}
                    isChecked={isChecked}
                    onChange={handleCheckboxChange}
                    color={textColor}
                    fontSize="sm"
                >
                    By checking this box I agree to MOLOBoost Terms of Service and Privacy Policy.
                </Checkbox>
                <Skeleton isLoaded={dataLoaded}>
                    <Button
                        fontSize="1em"
                        width="100%"
                        bg="#000"
                        _hover={{ bg: "#333" }}
                        fontWeight="700"
                        color={textColor}
                        isLoading={isDataLoading}
                        onClick={saveMember}
                    >
                        Post
                    </Button>
                </Skeleton>
            </Box>
        </Flex>
        )
    );
};

export default DataCap;